import { http } from '@/api/Connect';
import { BASE_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import { ECopyStakeIntegrationMode, TCopystakeIntegration } from '@/api/schema';

const API_INTEGRATION = (): string => `${BASE_OPERATOR()}/integration`;

export async function getCopystakeIntegration(): Promise<TCopystakeIntegration> {
  return http
    .get(API_INTEGRATION(), {
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<TCopystakeIntegration>) => data);
}

export async function updateCopystakeIntegration(
  params: TCopystakeIntegration
): Promise<void> {
  return http.put(API_INTEGRATION(), null, { params });
}

export async function updateCopystakeIntegrationMode(
  mode: ECopyStakeIntegrationMode
): Promise<unknown> {
  return http.patch(`${API_INTEGRATION()}/integration-mode`, null, {
    params: { mode }
  });
}

import { buildUrl } from '@/helpers/buildUrl';

// https://api.dev.trueplay.io/api/v1/app/api-gateway/webjars/swagger-ui/index.html?urls.primaryName=Streamers%20Admin%20Service%20(Admin%20API)
export const BASE = (): string => '/api/v1/app/control';

export const BASE_OPERATOR = (): string => buildUrl(`${BASE()}/{operatorId}`);

// https://api.dev.trueplay.io/api/v1/app/api-gateway/webjars/swagger-ui/index.html?urls.primaryName=Streamers%20Web%20Service%20(Admin%20API)
export const BASE_WEB = (): string => '/api/v1/app/web';

export const BASE_WEB_OPERATOR = (): string =>
  buildUrl(`${BASE_WEB()}/admin/{operatorId}`);

// https://api.dev.trueplay.io/api/v1/app/api-gateway/webjars/swagger-ui/index.html?urls.primaryName=Streamers%20Game%20Center%20Service%20(Admin%20API)
export const BASE_GAME_CENTER = (): string => '/api/v1/app/game-center';

export const BASE_GAME_CENTER_OPERATOR = (): string =>
  buildUrl(`${BASE_GAME_CENTER()}/admin/{operatorId}`);
